<template>
  <div class="main">
    <div key="card1" class="card1">
      <main-tittle ref="mainTittle" topTittle="湖北财经发展研究报告" subTittle="CPI与PPI"></main-tittle>
      <div class="commonCartWrap">
        <div class="tittle">CPI与PPI</div>
        <div class="tips">居民消费价格总指数（上年同期=100）(%)</div>
        <div class="intro">规模以上工业增加值-累计同比增长(%)</div>
        <line-chart ref="refLineChart" id="refLineChart" customClass="line-chart" />
        <div class="copyRight">数据来源：灯塔财经大数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { sleep } from "@/utils/common";
import mainTittle from "./mainTittle.vue";
import lineChart from "@/components/lineChart/index.vue";
export default {
  components: {
    // donutChart,
    // barChartHorizen,
    mainTittle,
    lineChart,
  },
  data: function () {
    return {
      currentPage: 1,
      lineData: [
        // { label: "2019-02", value1: 101.7, value2: 100.8 },
        // { label: "2019-03", value1: 101.9, value2: 100.9 },
        // { label: "2019-04", value1: 102.1, value2: 101.0 },
        // { label: "2019-05", value1: 102.3, value2: 101.0 },
        // { label: "2019-06", value1: 102.4, value2: 100.9 },
        // { label: "2019-07", value1: 102.4, value2: 100.8 },
        // { label: "2019-08", value1: 102.5, value2: 100.7 },
        // { label: "2019-09", value1: 102.6, value2: 100.6 },
        // { label: "2019-10", value1: 102.8, value2: 100.4 },
        // { label: "2019-11", value1: 102.9, value2: 100.3 },
        { label: "2019-12", value1: 103.1, value2: 100.2 },
        { label: "2020-02", value1: 106.0, value2: 100.1 },
        { label: "2020-03", value1: 106.1, value2: 100.0 },
        { label: "2020-04", value1: 105.7, value2: 99.6 },
        { label: "2020-05", value1: 105.0, value2: 99.3 },
        { label: "2020-06", value1: 104.5, value2: 99.2 },
        { label: "2020-07", value1: 104.4, value2: 99.1 },
        { label: "2020-08", value1: 104.2, value2: 99.1 },
        { label: "2020-09", value1: 103.8, value2: 99.0 },
        { label: "2020-10", value1: 103.4, value2: 99.0 },
        { label: "2020-11", value1: 103.0, value2: 99.0 },
        { label: "2020-12", value1: 102.7, value2: 99.1 },
        { label: "2021-02", value1: 98.5, value2: 100.3 },
        { label: "2021-03", value1: 98.5, value2: 100.7 },
        { label: "2021-04", value1: 98.8, value2: 101.3 },
        { label: "2021-05", value1: 99.2, value2: 101.8 },
        { label: "2021-06", value1: 99.5, value2: 102.2 },
        { label: "2021-07", value1: 99.7, value2: 102.6 },
        { label: "2021-08", value1: 99.8, value2: 102.9 },
        { label: "2021-09", value1: 99.9, value2: 103.2 },
        { label: "2021-10", value1: 100.0, value2: 103.6 },
        { label: "2021-11", value1: 100.2, value2: 103.9 }
      ],
    };
  },
  methods: {
    async playCardStart() {
      console.log("playCardStart..");
      await this.page1Fn();
      this.$emit("playEnd");
    },
    // 第1页
    async page1Fn() {
      await this.$refs.mainTittle.tittlePlay();
      $(".commonCartWrap").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".commonCartWrap .tittle").addClass("animate__animated animate__fadeIn ");
      $(".commonCartWrap .copyRight").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      this.loadchart();
      await sleep(7000);
    },
    loadchart() {
      this.$refs["refLineChart"].LineChart(this.lineData, {
        label: (d) => d.label,
        line1: (d) => d.value1,
        line2: (d) => d.value2,
        height: 340,
        marginTop: 30,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 40,
        duration: 2000,
        yDomain:[94,108],
        delay: 40,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
        isFromZero: false,
        xTickNumber: 5,
        yTickNumber: 6,
      });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.tips {
  color: #a0735c;
  font-weight: bold;
  font-size: 16px;
   position: relative;
   margin: 10px auto;
   padding-left: 15px;
}
.tips::before{
    position: absolute;
    content: "";
    width: 12px;
    height: 6px;
    background-color: #a0735c;
    left: 0;
    top: 7px;
}
.intro{
   color: #FFBD27;
   font-weight: bold;
   font-size: 16px;
   position: relative;
   margin: 10px auto;
   padding-left: 15px;
}
 .intro::before{
    position: absolute;
    content: "";
    width: 12px;
    height: 6px;
    background-color: #FFBD27;
    left: 0;
    top: 7px;
 }
.line-chart {
  margin-bottom: 20px;
  min-height: 200px;
  display: inline-block;
  /deep/ .line1 {
    stroke: #a0735c;
    stroke-width: 4px;
  }
  /deep/ .line2 {
    stroke: #ffbd27;
    stroke-width: 4px;
  }
  /deep/ .axis_y {
    .text {
      fill: #a0735c;
      font-size: 14px;
    }
    .domain,
    .tick_line {
      display: none;
    }
  }
  /deep/ .axis_x {
    .text {
      fill: #a0735c;
      font-size: 14px;
    }
    .domain,
    .tick_line {
      stroke: #a0735c;
    }
  }
  .tick_long_line {
    display: none;
  }
}
/deep/.commonCartWrap{
    height: 535px;
}
</style>
