<template>
  <div class="main">
    <div key="card1" class="card1">
      <main-tittle ref="mainTittle" topTittle="湖北财经发展研究报告" subTittle="工业生产"></main-tittle>
      <div class="commonCartWrap">
        <div class="tittle">工业增加值</div>
        <div class="intro">规模以上工业增加值-累计同比增长(%)</div>
        <line-chart ref="refLineChart" id="refLineChart" customClass="line-chart" />
        <div class="copyRight">数据来源：灯塔财经大数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { sleep } from "@/utils/common";
import mainTittle from "./mainTittle.vue";
import lineChart from "@/components/lineChart/index.vue";
export default {
  components: {
    // donutChart,
    // barChartHorizen,
    mainTittle,
    lineChart,
  },
  data: function () {
    return {
      currentPage: 1,
      lineData: [
        { label: "2019-02", value: 10.0 },
        { label: "2019-03", value: 9.9 },
        { label: "2019-04", value: 9.8 },
        { label: "2019-05", value: 9.1 },
        { label: "2019-06", value: 8.9 },
        { label: "2019-07", value: 8.6 },
        { label: "2019-08", value: 8.3 },
        { label: "2019-09", value: 8.3 },
        { label: "2019-10", value: 8.0 },
        { label: "2019-11", value: 7.8 },
        { label: "2019-12", value: 7.8 },
        { label: "2020-02", value: -46.2 },
        { label: "2020-03", value: -45.8 },
        { label: "2020-04", value: -33.6 },
        { label: "2020-05", value: -26.2 },
        { label: "2020-06", value: -20.8 },
        { label: "2020-07", value: -17.1 },
        { label: "2020-08", value: -14.0 },
        { label: "2020-09", value: -11.3 },
        { label: "2020-10", value: -9.3 },
        { label: "2020-11", value: -7.9 },
        { label: "2020-12", value: -6.1 },
        { label: "2021-02", value: 99.2 },
        { label: "2021-03", value: 96.0 },
        { label: "2021-04", value: 62.1 },
        { label: "2021-05", value: 45.9 },
        { label: "2021-06", value: 34.9 },
        { label: "2021-07", value: 28.2 },
        { label: "2021-08", value: 21.6 },
        { label: "2021-09", value: 17.3 },
        { label: "2021-10", value: 15.7 },
      ],
    };
  },
  methods: {
    async playCardStart() {
      console.log("playCardStart..");
      await this.page1Fn();
       this.$emit("playEnd");
    },
    // 第1页
    async page1Fn() {
      await this.$refs.mainTittle.tittlePlay();
      $(".commonCartWrap").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".commonCartWrap .tittle").addClass("animate__animated animate__fadeIn ");
      $(".commonCartWrap .copyRight").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      this.loadchart();
      await sleep(8000);
    },
    loadchart() {
      this.$refs["refLineChart"].LineChart(this.lineData, {
        label: (d) => d.label,
        line1: (d) => d.value,
        height: 340,
        marginTop: 30,
        marginBottom: 40,
        marginLeft: 50,
        marginRight: 40,
        duration: 2000,
        yDomain: [-60, 100],
        delay: 40,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
        isFromZero: false,
        xTickNumber: 5,
        yTickNumber: 6,
      });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.intro {
  color: #a0735c;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  margin: 10px auto;
  padding-left: 15px;
}
.intro::before {
  position: absolute;
  content: "";
  width: 12px;
  height: 6px;
  background-color: #a0735c;
  left: 0;
  top: 7px;
}
.line-chart {
  margin-bottom: 20px;
  min-height: 200px;
  display: inline-block;
  /deep/ .line1 {
    stroke: #a0735c;
    stroke-width: 4px;
  }
  /deep/ .axis_y {
    .text {
      fill: #a0735c;
      font-size: 14px;
    }
    .domain,
    .tick_line {
      display: none;
    }
  }
  /deep/ .axis_x {
    stroke: #a0735c;
    fill: #a0735c;
    .text {
      fill: #a0735c;
      font-size: 14px;
    }
    .domain,
    .tick_line {
      stroke: #a0735c;
    }
  }
  .tick_long_line {
    display: none;
  }
}
</style>
