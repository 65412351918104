<template>
  <div class="main">
    <!-- 第1页 -->
    <div key="card1" class="card1">
      <div class="topThx">
        <anime-txt
          id="news2"
          ref="news2"
          class="anime_txt"
          txt="感谢观看！"
          duration="1000"
          easing="easeOutExpo"
          staggerSpeed="fast"
          inEffect="fadeIn"
          outEffect="fadeOut"
        />
      </div>
      <div class="logo">
          <img src="../../assets/images/HB/logo.png">
      </div>
      <div class="rollWrap"> 
          <div class="rolled"> </div>
      </div>
      <div class="copyRight"> 
           <anime-txt
          id="copyRight"
          ref="copyRight"
          class="anime_txt"
          txt="以上数据来源：灯塔财经大数据"
          duration="1000"
          easing="easeOutExpo"
          staggerSpeed="fast"
          inEffect="fadeIn"
          outEffect="fadeOut"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import animeTxt from "@/components/animeTxt/index.vue";
import { sleep } from "@/utils/common";
export default {
  components: {
    animeTxt,
  },
  data: function () {
    return {
     
    };
  },
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      console.log("playCardStart..");
      await this.page1Fn();
      await sleep(1000);
    },
    // 第1页
    async page1Fn() {
      await sleep(1000);
      this.$refs.news2.playIn();
      await sleep(1000);
      $(".logo").addClass("animate__animated animate__fadeIn ");
      await sleep(1000);
        $(".rolled").css("opacity", 1);
        $(".rolled").addClass("slideRound")
       await sleep(1000);
        this.$refs.copyRight.playIn();
    },
  },
};
</script>

<style scoped lang='less'>

.topThx{
  position: absolute;
  top:407px;
  left: 205px;
  color:#fff;
  .anime_txt{
      font-size: 72px;
  }
}
.logo{
  position:absolute;
  top:555px;
  left: 226px;
  width:268px;
  height: 144px;
  opacity: 0;
}
.copyRight{
  color:#fff;
  position: absolute;
  left: 0;
  width:100%;
  bottom: 100px;
  text-align: center;
   .anime_txt{
      font-size: 32px;
   }
}
.rollWrap{
width:268px;
  height: 144px;
  position: absolute;
  border-radius: 30px;
			  top:555px;
        left: 226px;
  overflow: hidden;
}
.rolled {
				position:absolute;
        left: 0;
				width: 80px;
				height: 200px;
				background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0)0, rgba(255, 255, 255, .2)50%, rgba(255, 255, 255, 0)100%);
				transform: skewX(-35deg);
				overflow: hidden;
        opacity: 0;
			}
      .slideRound{
				animation: slideRoundAnimate 4s .2s ease both infinite;
      }
			@keyframes slideRoundAnimate {
				75% {
					left: 200px;
				}
				100% {
					left: 550px;
				}
			}
</style>