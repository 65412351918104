<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svgLineTotalLength: 0,
    };
  },
  methods: {
    async playOut() {
      d3.selectAll(`#${this.id} .line1`)
        .attr("stroke-dashoffset", 0)
        .transition()
        .duration(600)
        .ease(d3.easeCubicIn)
        .attr("stroke-dashoffset", -this.svgLineTotalLength);
      d3.selectAll(`#${this.id} .line2`)
        .attr("stroke-dashoffset", 0)
        .transition()
        .duration(600)
        .ease(d3.easeCubicIn)
        .attr("stroke-dashoffset", -this.svgLineTotalLength);

      d3.selectAll(`#${this.id} .axis_x .domain`).transition().duration(300).style("opacity", "0");
      d3.selectAll(`#${this.id} .axis_x .tick_line`).transition().duration(600).style("opacity", "0");
      d3.selectAll(`#${this.id} .axis_x .text`)
        .transition()
        .duration(300)
        .delay((d, i) => i * 40)
        .ease(d3.easeCircleOut)
        .style("opacity", "0");
      d3.selectAll(`#${this.id} .axis_y .domain`).transition().duration(300).style("opacity", "0");
      d3.selectAll(`#${this.id} .axis_y .tick_line`).transition().duration(600).style("opacity", "0");
      d3.selectAll(`#${this.id} .axis_y .text`)
        .transition()
        .duration(300)
        .delay((d, i) => i * 40)
        .ease(d3.easeCircleOut)
        .style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    LineChart(
      data,
      {
        label = (d, i) => d, // given d in data, returns the (ordinal) x-value
        line1 = (d) => d, // given d in data, returns the (quantitative) y-value
        line2 = (d) => d, // given d in data, returns the (quantitative) y-value
        // curveName = "curveMonotoneX",
        // curveName = 'curveNatural',
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeCubicInOut", //元素之间间隔时长
        isFromZero = true, //默认最小值从0开始
        xTickNumber = 5,
        yTickNumber = 5,
      } = {}
    ) {
      const curve = d3[curveName];
      const X = d3.map(data, label);
      const Y1 = d3.map(data, line1);
      const Y2 = d3.map(data, line2);
      const YArray = [d3.min(Y1), d3.max(Y1), d3.min(Y2), d3.max(Y2)];
      const I = d3.range(X.length);

      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [isFromZero ? 0 : d3.min(YArray), d3.max(YArray)];

      const xScale = d3.scalePoint(xDomain, xRange);
      const yScale = d3.scaleLinear(yDomain, yRange);
      //算出每几个标一个tick
      const modeNum = Math.ceil(data.length / xTickNumber);
      const xAxis = d3
        .axisBottom(xScale)
        .tickSizeOuter(0)
        .tickValues(xScale.domain().filter((d, i) => i % modeNum == 0));
      const yAxis = d3.axisLeft(yScale).ticks(yTickNumber, yFormat).tickSizeOuter(0);

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

      //y轴坐标轴
      const axisY = svg
        .append("g")
        .attr("class", "axis_y")
        .attr("transform", `translate(${marginLeft},0)`)
        .call(yAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
          g.selectAll(".tick line")
            .clone()
            .attr("x2", width - marginLeft - marginRight)
            .attr("stroke", "rgba(0,0,0,.1)")
            .attr("class", "tick_long_line");
          g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
        });
      axisY.selectAll(".domain").transition().duration(600).attr("opacity", 1);
      axisY.selectAll(".tick_line").transition().duration(600).attr("opacity", 1);
      axisY.selectAll(".tick_long_line").transition().duration(600).attr("opacity", 1);
      axisY
        .selectAll(".text")
        .transition()
        .delay((d, i) => i * delay)
        .ease(d3.easeCircleOut)
        .duration(600)
        .attr("opacity", 1);

      //x轴坐标轴
      const axisX = svg
        .append("g")
        .attr("class", "axis_x")
        .attr("transform", `translate(0,${height - marginBottom + 1})`)
        .call(xAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
          g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
        });

      axisX.selectAll(".domain").transition().duration(600).attr("opacity", 1);
      axisX.selectAll(".tick_line").transition().duration(600).attr("opacity", 1);
      axisX
        .selectAll(".text")
        .transition()
        .delay((d, i) => i * delay)
        .ease(d3.easeCircleOut)
        .duration(600)
        .attr("opacity", 1);

      //画线1
      const pathLine1 = d3
        .line()
        .curve(curve)
        .x((i) => xScale(X[i]))
        .y((i) => yScale(Y1[i]));

      const svgLine1 = svg
        .append("path")
        .attr("fill", "none")
        .attr("class", "line1")
        .attr("stroke", "#000")
        .attr("d", pathLine1(I));
      const svgLineTotalLength = svgLine1.node().getTotalLength();
      this.svgLineTotalLength = svgLineTotalLength;
      svgLine1
        .attr("stroke-dasharray", svgLineTotalLength + "," + svgLineTotalLength)
        .attr("stroke-dashoffset", svgLineTotalLength)
        .transition()
        .duration(duration)
        .ease(d3[ease])
        .attr("stroke-dashoffset", 0);

      if (!isNaN(Y2[0])) {
        //画线2
        const pathLine2 = d3
          .line()
          .curve(curve)
          .x((i) => xScale(X[i]))
          .y((i) => yScale(Y2[i]));
        const svgLine2 = svg
          .append("path")
          .attr("fill", "none")
          .attr("class", "line2")
          .attr("stroke", "#000")
          .attr("d", pathLine2(I));
        svgLine2
          .attr("stroke-dasharray", svgLineTotalLength + "," + svgLineTotalLength)
          .attr("stroke-dashoffset", svgLineTotalLength)
          .transition()
          .duration(duration)
          .ease(d3[ease])
          .attr("stroke-dashoffset", 0);
      }

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
