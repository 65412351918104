<template>
  <div class="main">
    <!-- 第1页 -->
    <div key="card1" class="card1">
      <main-tittle ref="mainTittle" topTittle='湖北财经发展研究报告' subTittle='消费市场' ></main-tittle>
      <div class="commonCartWrap"> 
          <div class="tittle"> 前三季消费行业增长情况 </div>
           <bar-chart ref="market1" id="market1" customClass="c_bar0"> </bar-chart>
          <div class="copyRight"> 数据来源：灯塔财经大数据 </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { sleep } from "@/utils/common";
import mainTittle from './mainTittle.vue'
import barChart from "@/components/barChart/index.vue";
export default {
  components: {
    mainTittle,
    barChart
  },
  data: function () {
    return {
     market1: [
        { label: "批发业", value: 21.1 },
        { label: "零售业", value: 25.9 },
        { label: "住宿业", value: 37.3 },
        { label: "餐饮业销售额", value: 52.2 },
      ],
    };
  },
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      console.log("playCardStart..");
      await this.page1Fn();
      this.$emit("playEnd");
    },
    // 第1页
    async page1Fn() {
      await this.$refs.mainTittle.tittlePlay()
      $(".commonCartWrap").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".commonCartWrap .tittle").addClass("animate__animated animate__fadeIn ");
      $(".commonCartWrap .copyRight").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      this.loadChart()
      await sleep(17000);
    },
    loadChart(idx) {
       console.log("loadChart..")
      this.$refs['market1'].BarChart(this.market1, {
        x: (d) => d.label,
        y: (d) => d.value,
         width: 680,
        height: 400,
        marginLeft: 40,
        duration: 1000,
        delay: 40,
        xPadding:0.5,
        ease: "easeBack",
        numberSuffix: "%",
        isFromZero: true,
        xTickNumber: 4,
        yTickNumber: 5,
      });
    }
  },
};
</script>

<style scoped lang='less'>

.c_bar0 {
  font-size: 14px;
  min-height: 200px;
  margin-bottom: 20px;
  margin-top:10px;
  /deep/ .numbers {
    font-size: 20px;
    fill: #A0735C;
  }
  /deep/ .y_label {
    font-size: 14px;
    fill: #A0735C;
  }
  /deep/ .axis_x {
    font-size: 14px;
    .tick_line,
    .domain {
      stroke: #A0735C;
    }
    .text {
      fill: #A0735C;
    }
  }
  /deep/ .axis_y {
    font-size: 20px;
    .tick_line,
    .domain {
      stroke: #A0735C;
    }
    .text {
      fill: #A0735C;
    }
  }
  /deep/ .bar_group rect{
    fill: #A0735C;
  }
}

// /deep/.commonCartWrap{
//     height: 585px;
// }
</style>