<template>
   <div class="topTittle"> 
           <div class="white">
            <anime-txt
              id="topTittle"
              ref="topTittle"
              class="anime_txt"
              :txt="topTittle"
              duration="1000"
              easing="easeOutExpo"
              staggerSpeed="fast"
              inEffect="typeIn"
              outEffect="fadeOut"
            />
          </div>
          <div class="line"> </div>
          <div class="orange">
              <anime-txt
              id="subTittle"
              ref="subTittle"
              class="anime_txt"
              :txt="subTittle"
              duration="1000"
              easing="easeOutExpo"
              staggerSpeed="fast"
              inEffect="typeIn"
              outEffect="fadeOut"
            />
          </div>
      </div>
</template>

<script>
import animeTxt from "@/components/animeTxt/index.vue";
import { sleep } from "@/utils/common";
export default {
  props: ["topTittle", "subTittle"],
  components: {
    animeTxt,
  },
  data: function () {
    return {
      
    };
  },
  methods: {
    // 第1页
    async tittlePlay() {
     this.$refs.topTittle.playIn();
      await sleep(800);
      $(".line").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      this.$refs.subTittle.playIn();
      await sleep(800);
    },
  },
};
</script>

<style scoped lang="less">
.topTittle{
  display: flex;
  position: absolute;
  left: 28px;
  top:120px;
  align-items: center;
  .anime_txt{
    font-size: 32px;
  }
  .white{
    color:#fff;
  }
  .orange{
    color:#F2580D;
  }
  .line{
    background: #F2580D;
    width:8px;
    height: 24px;
    opacity: 0;
    margin: 0 15px;
    position: relative;
    top:-10px;
  }
}
</style>