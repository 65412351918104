<template>
  <div class="width-100 height-100 max_box">
    <div id="particles-js"></div>
    <div class="coverBg"></div>
    <component :is="currentView" :cardData="cardData" ref="coms" @playEnd="playNext()"></component>

    <div class="model_macheal" id="modelMacheal">
      <load-model
        ref="refMacheal"
        :width="550"
        :height="550"
        :scale="5.1"
        modelName="juese1"
        :directionalLight="0xffffff"
        :originPositionY="0"
      />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import LoadModel from "@/components/models/loadModel.vue";
import { GetParams2 } from "../../utils/common";
import { sleep, randomNum } from "@/utils/utils.js";
import ApiServe from "@/api/index";
import page0 from "./page0.vue";
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import page5 from "./page5.vue";
import page6 from "./page6.vue";
import page7 from "./page7.vue";
import page8 from "./page8.vue";
import page9 from "./page9.vue";
import page10 from "./page10.vue";
import page11 from "./page11.vue";
import page12 from "./page12.vue";
export default {
  name: "guojun",
  components: {
    LoadModel,
    page0,
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
    page8,
    page9,
    page10,
    page11,
    page12,
  },
  data() {
    return {
      audioSrcList: [],
      audioTimeList: [],
      device_id: null,
      recordtimeAll: null,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      audioData: [],
      currentIndex: 0,
      aduioData: [], //旁白的数据
      cardData: {},
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
  },
  computed: {
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
    currentView() {
      if (this.currentIndex == 0) {
        return page0;
      }
      if (this.currentIndex == 1) {
        return page1;
      }
      if (this.currentIndex == 2) {
        return page2;
      }
      if (this.currentIndex == 3) {
        return page3;
      }
      if (this.currentIndex == 4) {
        return page4;
      }
      if (this.currentIndex == 5) {
        return page5;
      }
      if (this.currentIndex == 6) {
        return page6;
      }
      if (this.currentIndex == 7) {
        return page7;
      }
      if (this.currentIndex == 8) {
        return page8;
      }
      if (this.currentIndex == 9) {
        return page9;
      }
      if (this.currentIndex == 10) {
        return page10;
      }
      if (this.currentIndex == 11) {
        return page11;
      }
      if (this.currentIndex == 12) {
        return page12;
      }
      return page0;
    },
  },
  async mounted() {
    await this.$refs.refMacheal.init();
    if (this.ip && this.port) {
      console.log(666);
      const params = {
        ip: this.ip,
        port: this.port,
        offset_x: this.offset_x,
        offset_y: this.offset_y,
        width: this.width,
        height: this.height,
      };
      ApiServe.recordstartPc(this.newIpPort, params)
        .then(({ code }) => {
          this.load();
        })
        .catch((err) => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    } else {
      // alert("没获取到ip,port");
      this.load();
    }
  },
  methods: {
    async actionLoop(refName, actions) {
      this.$refs[refName].playAction(actions[randomNum(0, actions.length - 1)]);
      await sleep(randomNum(3, 4) * 1000);
      this.$refs[refName].playAction("Idle");
      await sleep(randomNum(3, 6) * 1000);
      this.actionLoop(refName, actions);
    },
    endRecord() {
      this.aduioData = [
        //page0
        {
          audioLink: "https://res.idtcdn.com/Cube/video-config/con-f1c50d84.mp3",
          timeIndex: 0,
          audioLen: 2,
        },
        //page1
        {
          audioLink: "https://res.idtcdn.com/Cube/video-config/con-ebf8f9a7.mp3",
          timeIndex: 5,
          audioLen: 12,
        },
        //page2
        {
          audioLink: "https://res.idtcdn.com/Cube/video-config/con-a7aadae4.mp3",
          timeIndex: 19,
          audioLen: 6,
        },
        //page3
        {
          audioLink: "https://res.idtcdn.com/Cube/video-config/con-d155d81b.mp3",
          timeIndex: 27,
          audioLen: 16,
        },
        //page4
        {
          audioLink: "https://res.idtcdn.com/Cube/video-config/con-6211896a.mp3",
          timeIndex: 45,
          audioLen: 9,
        },
        //page5
        {
          audioLink: "https://res.idtcdn.com/Cube/video-config/con-3b50cba7.mp3",
          timeIndex: 56,
          audioLen: 8,
        },
        //page6
        {
          audioLink: "https://res.idtcdn.com/Cube/video-config/con-c8f8eb90.mp3",
          timeIndex: 66,
          audioLen: 18,
        },
        //page7
        {
          audioLink: "https://res.idtcdn.com/Cube/video-config/con-bb04a220.mp3",
          timeIndex: 86,
          audioLen: 15,
        },
        //page8,page9
        {
          audioLink: "https://res.idtcdn.com/Cube/video-config/con-2a7f2147.mp3",
          timeIndex: 103,
          audioLen: 19,
        },
        //page10
        {
          audioLink: "https://res.idtcdn.com/Cube/video-config/con-ff61acc7.mp3",
          timeIndex: 126,
          audioLen: 8,
        },
        //page11
        {
          audioLink: "https://res.idtcdn.com/Cube/video-config/con-0eee9473.mp3",
          timeIndex: 136,
          audioLen: 12,
        },
        //page12
      ];
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: 155,
        videmBgm: "https://res.idtcdn.com/Cube/video-config/con-ec094cc4.mp3",
        audiosOrderList: this.aduioData,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(this.newIpPort, this.ip, this.port, data)
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    initParticle() {
      console.log(666);
      particlesJS("particles-js", {
        particles: {
          number: { value: 80, density: { enable: true, value_area: 800 } },
          color: { value: "#ffffff" },
          shape: {
            type: "circle",
            stroke: { width: 0, color: "#000000" },
            polygon: { nb_sides: 5 },
            image: { src: "img/github.svg", width: 100, height: 100 },
          },
          opacity: {
            value: 0.4,
            random: false,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
          },
          size: {
            value: 3,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 1,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: false, mode: "bubble" },
            onclick: { enable: false, mode: "repulse" },
            resize: true,
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: {
              distance: 250,
              size: 0,
              duration: 2,
              opacity: 0,
              speed: 3,
            },
            repulse: { distance: 400, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 },
          },
        },
        retina_detect: true,
      });
    },
    // 页面card一个接一个播放
    async load() {
      // this.$refs.bgAudio.volume = 0.1;
      // this.$refs.bgAudio.play();
      this.initParticle();
      this.$refs.coms.playCardStart();

      //macheal动作
      this.machealActionTimeline();

      // 总时间 94 + 3秒
      if (this.ip) {
        setTimeout(() => {
          this.endRecord();
        }, 155 * 1000);
      }
    },
    async machealActionTimeline() {
      //page0, 0开始
      console.log('page0');
      await this.$refs.refMacheal.playAction("Run");
      await sleep(2 * 1000);
      await this.$refs.refMacheal.playAction("Idle");
      await sleep(3 * 1000);
      console.log('page1');
      //page1, 5开始
      await this.$refs.refMacheal.playAction("Wave");
      await sleep(3 * 1000);
      await this.$refs.refMacheal.playAction("Walk");
      await sleep(11 * 1000);
      console.log('page2');
      //page2, 19开始
      await this.$refs.refMacheal.playAction("Point");
      await sleep(2 * 1000);
      await this.$refs.refMacheal.playAction("Walk");
      await sleep(6 * 1000);
      console.log('page3');
      //page3, 27开始
      await this.$refs.refMacheal.playAction("Point");
      await sleep(2 * 1000);
      await this.$refs.refMacheal.playAction("Walk");
      await sleep(16 * 1000);
      //page4, 45开始
      console.log('page4');
      await this.$refs.refMacheal.playAction("Point");
      await sleep(2 * 1000);
      await this.$refs.refMacheal.playAction("Walk");
      await sleep(9 * 1000);
      //page5, 56开始
      console.log('page5');
      await this.$refs.refMacheal.playAction("Point");
      await sleep(2 * 1000);
      await this.$refs.refMacheal.playAction("Walk");
      await sleep(8 * 1000);
      //page6, 66开始
      console.log('page6');
      await this.$refs.refMacheal.playAction("Point");
      await sleep(2 * 1000);
      await this.$refs.refMacheal.playAction("Walk");
      await sleep(18 * 1000);
      //page7, 86开始
      console.log('page7');
      await this.$refs.refMacheal.playAction("Point");
      await sleep(2 * 1000);
      await this.$refs.refMacheal.playAction("Walk");
      await sleep(15 * 1000);
      //page8，9, 103开始
      console.log('page8，9');
      await this.$refs.refMacheal.playAction("Point");
      await sleep(2 * 1000);
      await this.$refs.refMacheal.playAction("Walk");
      await sleep(21 * 1000);
      //page10, 126开始
      console.log('page10');
      await this.$refs.refMacheal.playAction("Point");
      await sleep(2 * 1000);
      await this.$refs.refMacheal.playAction("Walk");
      await sleep(8 * 1000);
      //page11, 136开始
      console.log('page11');
      await this.$refs.refMacheal.playAction("Point");
      await sleep(6 * 1000);
      await this.$refs.refMacheal.playAction("Walk");
      await sleep(3 * 1000);
      await this.$refs.refMacheal.playAction("Wave");
      await sleep(4 * 1000);
      await this.$refs.refMacheal.playAction("Idle");
      
    },
    playNext() {
      this.currentIndex++;
      this.$nextTick(() => {
        this.$refs.coms.playCardStart();
      });
    },
  },
};
</script>
<style scoped lang="less">
#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #101c46;
  // background:url(../../assets/images/HB/bg.png) no-repeat 0 100%;
}
#particles-js.particle1 {
  // background: #000;
}
// .coverBg{
//   background:url(../../assets/images/HB/bg.png) no-repeat 0 100%;
//   position: absolute;
//   left: 0;
//   top:30%;
//   bottom: 0;
//   right:0;
// }
</style>
<style lang="less">
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.animate__animated {
  animation-fill-mode: both;
}

.model_macheal {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99;
  width: 550px;
  height: 550px;
}

.commonCartWrap {
  width: 664px;
  height: 505px;
  position: absolute;
  left: 28px;
  top: 320px;
  background: rgba(16, 28, 70, 0.95);
  border: solid 3px #a0735c;
  border-radius: 12px;
  // background: url(../../assets/images/HB/chartBg.png) no-repeat;
  padding: 15px;
  font-size: 28px;
  opacity: 0;
  .tittle {
    color: #fff;
    font-size: 30px;
    opacity: 0;
  }
  .copyRight {
    position: absolute;
    right: 15px;
    bottom: 15px;
    color: #dfb29c;
    opacity: 0;
    font-size: 18px;
  }
}
</style>
