<template>
  <div class="main">
    <!-- 第1页 -->
    <div key="card1" class="card1">
      <main-tittle
        ref="mainTittle"
        topTittle="湖北财经发展研究报告"
        subTittle="导读"
      ></main-tittle>
      <div class="commonCartWrap">
        <div class="tittle">湖北省GDP</div>
        <div ref="chart" style="height: 370px"></div>
        <div class="copyRight">数据来源：灯塔财经大数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { sleep } from "@/utils/common";
import mainTittle from "./mainTittle.vue";
import * as d3 from "d3";
export default {
  components: {
    mainTittle,
  },
  data: function () {
    return {};
  },
  methods: {
    drawChart() {
      //定义画布
      var width = 630;
      var height = 370;

      var svg = d3
        .select(this.$refs["chart"])
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      this.drawLegend(svg);

      var padding = { top: 20, bottom: 20, right: 20, left: 25 };
      var rectStep = 35;
      var rectWidth = 30;

      //比例尺
      //数据
      //定义比例尺
      var xScale = d3
        .scaleBand()
        .range([30, 590])
        .domain([
          "2018年1季度",
          "2018年3季度",
          "2019年1季度",
          "2019年3季度",
          "2020年1季度",
          "2020年3季度",
          "2021年1季度",
          "2021年3季度",
        ]);

      var y1Scale = d3.scaleLinear().domain([50000, 0]).range([50, 300]);
      var y2Scale = d3.scaleLinear().domain([50, -50]).range([-100, 150]);

      //坐标轴
      //定义坐标轴，其中使用了线性比例尺linear
      var xAxis = d3.axisBottom().scale(xScale);
      //追加到画布上
      var gxAxis = svg
        .append("g")
        .attr("class", "axis")
        .attr("transform", "translate(10,350)")
        .call(xAxis);

      var y1Axis = d3.axisLeft().scale(y1Scale);

      var y2Axis = d3.axisRight().scale(y2Scale);

      var gy1Axis = svg
        .append("g")
        .attr("class", "axis")
        .attr("transform", "translate(50,50)")
        .call(y1Axis);

      var gy2Axis = svg
        .append("g")
        .attr("class", "axis")
        .attr("transform", "translate(600,200)")
        .call(y2Axis);

      //柱形图
      var dataset = [
        8188.84, 17958.17, 27634.35, 39366.55, 9110.05, 19895.75, 30509.42,
        45828.31, 6379.35, 17480.51, 29779.42, 43447.46, 9872.67, 22777.69,
        34731.56,
      ];

      y1Scale.domain([0, 50000]);

      var rects = svg
        .selectAll(".bar" + 0)
        .data(dataset)
        .enter()
        .append("rect")
        .attr("fill", "#A0735C")
        .attr("transform", "translate(35,130)")
        .attr("x", function (d, i) {
          return padding.left + i * rectStep;
        })
        .attr("y", function (d) {
          return height - 100 - y1Scale(d);
          //return padding.left+rectStep
        })
        .attr("width", rectWidth)
        // .attr("height", function (d) {
        //   return y1Scale(d) - 50;
        // });
        .attr("height", 0);

      rects
        .transition()
        .delay((d, i) => i * 400)
        .duration(400)
        .ease(d3["easeExpOut"])
        .attr("height", (d, i) => {
          return y1Scale(d) - 50;
        });

      // rects
      //   .transition()
      //   .delay((d, i) => i * 40)
      //   .duration(2000)
      //   .ease(d3["easeBounce"]);

      // svg
      //   .append("g")
      //   .call(y1Axis)
      //   .append("text")
      //   .text("销售额")
      //   .attr("transform", "translate(60,50)") //text放置的位置
      //   .attr("text-anchor", "end") //字体尾部对齐
      //   .attr("dy", 40); //沿y轴平移一个字体的大小

      // svg
      //   .append("g")
      //   .call(y1Axis)
      //   .append("text")
      //   .text("增长率(%)")
      //   .attr("transform", "translate(500,50)") //text放置的位置
      //   .attr("text-anchor", "end") //字体尾部对齐
      //   .attr("dy", 40); //沿y轴平移一个字体的大小

      // svg
      //   .append("g")
      //   .call(y1Axis)
      //   .append("text")
      //   .text("时间")
      //   .attr("transform", "translate(480,380)") //text放置的位置
      //   .attr("text-anchor", "end") //字体尾部对齐
      //   .attr("dy", 40); //沿y轴平移一个字体的大小

      // 线段生成器
      var x1 = 20;
      var lines = [
        { x: x1, y: 7.6 },
        { x: x1 + rectStep, y: 7.8 },
        { x: x1 + rectStep * 2, y: 7.9 },
        { x: x1 + rectStep * 3, y: 7.8 },
        { x: x1 + rectStep * 4, y: 8.1 },
        { x: x1 + rectStep * 5, y: 8.0 },
        { x: x1 + rectStep * 6, y: 7.8 },
        { x: x1 + rectStep * 7, y: 7.5 },
        { x: x1 + rectStep * 8, y: -39.2 },
        { x: x1 + rectStep * 9, y: -19.3 },
        { x: x1 + rectStep * 10, y: -10.4 },
        { x: x1 + rectStep * 11, y: -5.0 },
        { x: x1 + rectStep * 12, y: 58.3 },
        { x: x1 + rectStep * 13, y: 28.5 },
        { x: x1 + rectStep * 14, y: 18.7 },
      ];

      var line = d3
        .line()
        .x(function (d) {
          return d.x;
        })
        .y(function (d) {
          return y2Scale(d.y) - 25;
        });
      var d = line(lines);
      var line1 = svg
        .append("path")
        .attr("d", line(lines))
        .attr("stroke-dasharray", function () {
          let totalLength = d3.select(this).node().getTotalLength(); //返回路径的总长度
          return totalLength;
        })
        .attr("stroke-dashoffset", function () {
          return d3.select(this).node().getTotalLength();
        })
        .attr("class", "MyPath2")
        .attr("stroke", "#ffbd27")
        .attr("stroke-width", "4px")
        .attr("fill", "none")
        .attr("transform", "translate(50,220)")
        .transition()
        .duration(2000)
        .ease(d3.easeLinear)
        .attr("stroke-dashoffset", 0);
    },
    drawLegend(svg) {
      var legend = svg
        .append("g")
        .attr("class", "legend")
        .attr("x", 65)
        .attr("y", 25)
        .attr("height", 100)
        .attr("width", 100);

      legend
        .append("rect")
        .attr("x", 65)
        .attr("y", 25)
        .attr("width", 10)
        .attr("height", 10)
        .style("fill", "#A0735C");

      legend
        .append("text")
        .attr("x", 65 + 20)
        .attr("y", 25 + 10)
        .text("生产总值（亿元）")
        .style("fill", "#A0735C");

      legend.append("rect").style("fill", "#A0735C");

      var legend3 = svg
        .append("g")
        .attr("class", "legend")
        .attr("x", 65 + 250)
        .attr("y", 25)
        .attr("height", 100)
        .attr("width", 100);

      legend3
        .append("rect")
        .attr("x", 65 + 250)
        .attr("y", 28)
        .attr("width", 10)
        .attr("height", 5)
        .style("fill", "#ffbd27");

      legend3
        .append("text")
        .attr("x", 65 + 20 + 250)
        .attr("y", 25 + 10)
        .text("同比增值%")
        .style("fill", "#ffbd27");

      legend3.append("rect").style("fill", "#ffbd27");
    },
    // 页面card一个接一个播放
    async playCardStart() {
      console.log("playCardStart..");
      await this.page1Fn();
      await sleep(1000);
      this.$emit("playEnd");
    },
    // 第1页
    async page1Fn() {
      await this.$refs.mainTittle.tittlePlay();
      $(".commonCartWrap").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".commonCartWrap .tittle").addClass(
        "animate__animated animate__fadeIn "
      );
      $(".commonCartWrap .copyRight").addClass(
        "animate__animated animate__fadeIn "
      );
      await sleep(200);
      this.drawChart();
      await sleep(10300);
    },
  },
};
</script>

<style lang="less">
.axis path,
.axis line {
  fill: none;
  stroke: #a0735c;
  shape-rendering: crispEdges;
}
.axis text {
  font-family: sans-serif;
  font-size: 11px;
  color: #a0735c;
}
.MyRect {
  fill: #a0735c;
}
.MyText {
  fill: #a0735c;
  text-anchor: middle;
}
.MyPath2 {
  fill: none;
  stroke: #ffbd27;
  stroke-width: 4px;
}
</style>
