<template>
  <div class="main">
    <!-- 第1页 -->
    <div key="card1" class="card1">
      <main-tittle ref="mainTittle" topTittle='湖北财经发展研究报告' subTittle='农业生产' ></main-tittle>
      <div class="commonCartWrap"> 
          <div class="tittle"> 前三季度猪、牛、羊、水产产量 </div>
          <bar-chart-horizen ref="market1" id="market1" class="chart c_bar1"> </bar-chart-horizen>
          <div class="copyRight"> 数据来源：灯塔财经大数据 </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { sleep } from "@/utils/common";
import mainTittle from './mainTittle.vue'
import barChartHorizen from "@/components/barChartHorizen/index.vue";
export default {
  components: {
    mainTittle,
    barChartHorizen
  },
  data: function () {
    return {
     market1: [
        { label: "猪", value: 3241.17 },
        { label: "牛", value: 66.56 },
        { label: "羊", value: 385.98 },
        { label: "水产", value: 350.89 },
      ],
    };
  },
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      console.log("playCardStart..");
      await this.page1Fn();
      this.$emit("playEnd");
    },
    // 第1页
    async page1Fn() {
      await this.$refs.mainTittle.tittlePlay()
      $(".commonCartWrap").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".commonCartWrap .tittle").addClass("animate__animated animate__fadeIn ");
      $(".commonCartWrap .copyRight").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      this.loadChart()
      await sleep(15000);
    },
    loadChart(idx) {
       console.log("loadChart..")
      this.$refs['market1'].BarChart(this.market1, {
        x: (d) => d.value,
        y: (d) => d.label,
        width: 680,
        height: 510,
        marginLeft: 120,
        marginRight: 280,
        duration: 400,
        delay: 400,
        yPadding: 0.7,
         isShowNumber: true,
        ease: "easeExp",
        numberSuffix: "万",
        numberPosition: "outside",
        labelPosition: "top"
      });
    }
  },
};
</script>

<style scoped lang='less'>
.c_bar1 {
  font-size: 14px;
  min-height: 200px;
  display: inline-block;
  margin-bottom: 30px;
  /deep/ .numbers {
    font-size: 20px;
    fill:  #A0735C;
  }
  /deep/ .axis_y {
    font-size: 20px;
    .text {
      fill:  #A0735C;
    }
  }
  // '#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69', '#fccde5', '#d9d9d9', '#bc80bd', '#ccebc5', '#ffed6f'
  /deep/ .bar_group rect {
    fill: #A0735C;
  }
  /deep/ .numbers {
    fill:  #A0735C;
  }
  /deep/ .numbers.reverse {
    fill:  #A0735C;
  }
}

/deep/.commonCartWrap{
    height: 585px;
}
</style>