<template>
  <div class="main">
    <!-- 第1页 -->
    <div key="card1" class="card1">
      <!-- <div class="bigRedFont">
        <anime-txt
          id="news2"
          ref="news2"
          class="anime_txt"
          txt=""
          duration="1000"
          easing="easeOutExpo"
          staggerSpeed="normal"
          inEffect="topIn"
          outEffect="fadeOut"
        />
      </div>
      <div class="year">
        <anime-txt
          id="year"
          ref="year"
          class="anime_txt"
          txt=""
          duration="1000"
          easing="easeOutExpo"
          staggerSpeed="fast"
          inEffect="topIn"
          outEffect="fadeOut"
        />
      </div>
      <div class="logo"></div> -->
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to";
import _ from "lodash";
// import animeTxt from "@/components/animeTxt/index.vue";
import { sleep } from "@/utils/common";
import donutChart from "@/components/donutChart/index.vue";
import barChartHorizen from "@/components/barChartHorizen/index.vue";
export default {
  components: {
    // donutChart,
    // barChartHorizen,
    // countTo,
    // animeTxt,
  },
  data: function () {
    return {
      currentPage: 1,
    };
  },
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      console.log("playCardStart..");
      await this.page1Fn();
      this.$emit("playEnd");
    },
    // 第1页
    async page1Fn() {
      // this.$refs.news2.playIn();
     
      // this.$refs.year.playIn();
      await sleep(1000);
      $(".logo").addClass("animate__animated animate__fadeIn ");
      await sleep(2000);
    },
  },
};
</script>

<style scoped lang='less'>
.card1 {
  position: absolute;
  width: 100%;
  height: 100%;
  // height: 1280px;
  background: url(../../assets/images/HB/card1_bg.png) no-repeat;
}
.bigRedFont {
  padding-top: 300px;
  color: #ffffff;
  text-align: center;
  .anime_txt {
    font-size: 60px;
  }
}
.year {
  color: #fff;
  text-align: center;
  margin-top: 10px;
  .anime_txt {
    font-size: 48px;
  }
}
.logo {
  color: #fff;
  font-size: 32px;
  text-align: center;
  position: absolute;
  bottom: 90px;
  left: 0;
  width: 100%;
  opacity: 0;
}
</style>