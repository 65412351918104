<template>
  <div class="main">
    <div key="card1" class="card1">
      <main-tittle ref="mainTittle" topTittle="湖北财经发展研究报告" subTittle="财政收支"></main-tittle>
      <div class="commonCartWrap">
        <div class="tittle">2021年11月湖北省金融机构本外币境内贷款余额情况</div>
        <donut-chart ref="refChart" id="refChart" customClass="chart" />
        <div class="textcen">
          <p>境内贷款</p>
          <p>
             <countTo
              :startVal="0"
              :endVal="total"
              :decimals="2"
              :duration="2000"
            ></countTo>亿</p>
        </div>
        <div class="copyRight">数据来源：灯塔财经大数据</div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { sleep } from "@/utils/common";
import countTo from "vue-count-to";
import mainTittle from "./mainTittle.vue";
import donutChart from "@/components/donutChart/index.vue";
export default {
  components: {
    mainTittle,
    donutChart,
    countTo
  },
  data: function () {
    return {
      currentPage: 1,
      chartData: [
        { name: "非金融企业及机关团体", value: 43832.61 },
        { name: "住户贷款", value: 20965.63 },
        { name: "非银行业金融机构", value: 12.00 }
      ],
      total:undefined
    };
  },
  methods: {
    async playCardStart() {
      console.log("playCardStart..");
      await this.page1Fn();
       this.$emit("playEnd");
    },
    // 第1页
    async page1Fn() {
      await this.$refs.mainTittle.tittlePlay();
      $(".commonCartWrap").addClass("animate__animated animate__fadeIn ");
      await sleep(300);
      $(".commonCartWrap .tittle").addClass("animate__animated animate__fadeIn ");
      $(".commonCartWrap .copyRight").addClass("animate__animated animate__fadeIn ");
      await sleep(200);
      this.loadchart();
      await sleep(500);
       this.total= 64810.24
      $(".textcen").addClass("animate__animated animate__fadeIn ");
       await sleep(7500);
    },
    loadchart() {
      this.$refs["refChart"].DonutChart(this.chartData, {
        name: (d) => d.name,
        value: (d) => d.value,
         width: 628,
        height: 510,
        donutRatio: 0.35, //donut的内圆半径占比
        labelRatio: 0.65, //label的半径占比
        colors: ["#F29A0D", "#893618","#845A55"],
        duration: 800,
        padAngle: 0,
        cornerRadius: 0, //圆角
        delay: 50,
        ease: "easeBackInOut",
        labelPosition: "outsideWithLine",
        valueType: "absolute",
        valueSuffix: "亿元",
      });
    },
  },
  mounted() {
    
  },
};
</script>

<style scoped lang="less">
.textcen {
   position: absolute;
    width: 181px;
    height: 181px;
    background: #fff;
    border-radius: 50%;
    left: 239px;
    text-align: center;
    top: 235px;
    opacity: 0;
  p {
    font-size: 21px;
    font-weight: bold;
    line-height: 25px;
    color: #313131;
  }
  p:first-child {
    margin-top: 62px;
  }
}
.chart {
  font-size: 16px;
  min-height: 200px;
   margin-top:-27px;
  display: inline-block;
  /deep/ .donut_txt1 {
    font-size: 18px;
  }
  /deep/ .donut_txt2 {
    font-size: 18px;
  }
  /deep/ .poly_lines {
    stroke-dasharray: 11px 2px;
    stroke-width: 2px;
  }
}
/deep/.commonCartWrap{
    height: 585px;
}
</style>
